<template>
    <div class="content_container realestate_pf">
		<div class="content">
			<div class="banner">
				<h1>기업금융</h1>
			</div>
			<ul class="common_tab">
				<li>
					<router-link to="/product/EnterpriseNormal">일반 대출</router-link>
				</li>
				<li class="current">
					<router-link to="/product/EnterpriseRealty">부동산 PF</router-link>
				</li>
			</ul>
			<h2 class="title_product">부동산 PF</h2>
			<section class="overview">
				<h3>개요</h3>
				<ul>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/realestate_pf/realestate_pf_overview_icon01.svg" alt="Project Financing" />
							<p>Project<br />Financing</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/realestate_pf/realestate_pf_overview_icon02.svg" alt="Long-term Finance" />
							<p>Long-term<br />Finance</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/realestate_pf/realestate_pf_overview_icon03.svg" alt="Future Cash Flow " />
							<p>Future<br />Cash Flow</p>
						</div>
					</li>
				</ul>
				<div class="textBox">
					<div class="float_wrapper">
						<ul class="float_content">
							<li>
								<img src="@/assets/images/sub/realestate_pf/icon_enterpriserealty_overview_01.svg" alt="대출대상" />
								<p class="title">대출대상</p>
								<p class="text">부동산 개발사업을 시행하는 기업체 또는 펀드</p>
							</li>
							<li>
								<img src="@/assets/images/sub/realestate_pf/icon_enterpriserealty_overview_02.svg" alt="대출상품" />
								<p class="title">대출상품</p>
								<p class="text">부동산PF, 브릿지론, ABCP 인수, 부동산펀드, 부동산개발 관련 구조화 금융 등</p>
							</li>
							<li>
								<img src="@/assets/images/sub/realestate_pf/icon_enterpriserealty_overview_03.svg" alt="상환방식" />
								<p class="title">상환방식</p>
								<p class="text">개발사업 일정에 맞춰 조정 (만기 일시상환, 원리금 분할 상환 등)</p>
							</li>
							<li>
								<img src="@/assets/images/sub/realestate_pf/icon_enterpriserealty_overview_04.svg" alt="적용이자" />
								<p class="title">적용이자</p>
								<p class="text">사업성 및 시공사 신용공여 형태에 따라 결정</p>
							</li>
							<li>
								<img src="@/assets/images/sub/realestate_pf/icon_enterpriserealty_overview_05.svg" alt="취급수수료" />
								<p class="title">취급수수료 </p>
								<p class="text">취급 조건에 따른 차등 적용</p>
							</li>
						</ul>
					</div>
					<!-- <p>부동산 개발사업에 대하여 사업성 및 관련업체의 신용도를 바탕으로 사업에 소요되는 자금을 지원해주는 금융입니다.</p> -->
				</div>
			</section>
			<section class="structure">
				<h3>운용구조</h3>
				<div class="structure_infogp">
					<p><img src="@/assets/images/sub/realestate_pf/realestate_structure_infogp.png" alt="부동산pf 운용구조" /></p> 
				</div>	
			</section>
			<section class="document">
				<h3>구비서류</h3>
				<div class="float_wrapper">
					<ul class="float_content">
						<li>
							<div class="img_wrapper">
								<img src="@/assets/images/sub/realestate_pf/icon_enterpriserealty_document_01.svg" alt="프로젝트 검토자료" />
							</div>
							<p class="title">프로젝트 검토자료</p>
							<p class="text"><span>사업계획서</span><span>사업수지표</span><span>인허가서류 등</span></p>
						</li>
						<li>
							<div class="img_wrapper">
								<img src="@/assets/images/sub/realestate_pf/icon_enterpriserealty_document_02.svg" alt="관련계약서" />
							</div>
							<p class="title">관련계약서</p>
							<p class="text"><span>공사도급계약서</span><span>신탁계약서 등</span></p>
						</li>
						<li>
							<div class="img_wrapper">
								<img src="@/assets/images/sub/realestate_pf/icon_enterpriserealty_document_03.svg" alt="기타자료" />
							</div>
							<p class="title">기타자료</p>
							<p class="text"><span>시행사</span><span>시공사 및 이해관계인 관련 자료 등</span></p>
						</li>
					</ul>
				</div>
			</section>

			<Online />
		</div>
	</div>
</template>

<script>
import Online from "@/components/Online.vue"

export default {
    name : "InvestPrivate",
	components: { 
		Online
	},
}
</script>

